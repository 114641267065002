/*
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-03-12 17:35:28
 * @LastEditors: hutian
 * @LastEditTime: 2021-03-13 17:24:59
 */
/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listDealerTemplate = params => axios({
    url: '/api/base/dealer/dealerTemplate/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addDealerTemplate = params => axios({
    url: '/api/base/dealer/dealerTemplate/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editDealerTemplate = params => axios({
    url: '/api/base/dealer/dealerTemplate/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delDealerTemplate = params => axios({
    url:'/api/base/dealer/dealerTemplate/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const updateDealerTemplateStatus = params => axios({
    url:'/api/base/dealer/dealerTemplate/status/' + params,
    method: 'GET',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdDealerTemplate = params => axios({
    url: '/api/base/dealer/dealerTemplate/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
